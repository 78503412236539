<template>
	<h1>Impressum</h1>
</template>

<script>
export default {
	name: "impressumWebsite",
	setup() {},
};
</script>

<style>
</style>